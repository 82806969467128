import _defineProperty from "/builds/u5yGSXwH/1/ms/ecom/deep/presentation-react/apps/next-commerce/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

var _CertificateName;

import { CertificateType } from '@src/types/graphql-types';
export var CertificateName = (_CertificateName = {}, _defineProperty(_CertificateName, CertificateType.Coa, 'CERTIFICATE_OF_ANALYSIS'), _defineProperty(_CertificateName, CertificateType.Coo, 'CERTIFICATE_OF_ORIGIN'), _defineProperty(_CertificateName, CertificateType.Coq, 'CERTIFICATE_OF_QUALITY'), _CertificateName);
export var getCooOrCoqType = function getCooOrCoqType(isBrandMM) {
  return isBrandMM ? CertificateType.Coq : CertificateType.Coo;
};
/**
 * Converts a PDF content string into a Blob and triggers a download of the PDF file.
 *
 * @param {string} pdfString - The content of the PDF as a string.
 * @param {string} [filename] - The desired name for the downloaded file. If not provided, defaults to 'download'.
 */

export var downloadPDF = function downloadPDF(pdfString, filename) {
  var pdfArray = new Uint8Array(Array.from(pdfString, function (_char) {
    return _char.charCodeAt(0);
  }));
  var pdfBlob = new Blob([pdfArray], {
    type: 'application/pdf'
  });
  var pdfUrl = URL.createObjectURL(pdfBlob);
  var link = document.createElement('a');
  link.href = pdfUrl;
  var sanitizedFilename = (filename === null || filename === void 0 ? void 0 : filename.replace(/\./g, '-')) || 'download';
  link.setAttribute('download', sanitizedFilename);
  link.click(); // clean up the object URL

  URL.revokeObjectURL(pdfUrl);
};